import { useEffect } from 'react';
import { useRouter } from 'next/router';
import mixpanel from 'mixpanel-browser';

// Types
import type { Config } from 'mixpanel-browser';

// Hooks
import useUser from '@/utils/hooks/swr/useUser';

export function MixPanelInitialize() {
    const defaultMixPanelConfig: Partial<Config> = {
        persistence: 'localStorage',
        ignore_dnt: true //bypass the do not track browser setting
    };

    mixpanel.init(
        process.env.NEXT_PUBLIC_MIXPANEL_API_KEY,
        process.env.NEXT_PUBLIC_MIXPANEL_API_HOST
            ? { ...defaultMixPanelConfig, api_host: process.env.NEXT_PUBLIC_MIXPANEL_API_HOST }
            : defaultMixPanelConfig
    );

    return <MixPanelInitializeUser />;
}

function MixPanelInitializeUser() {
    const router = useRouter();
    const { user } = useUser();

    useEffect(() => {
        if (!user) {
            return;
        }
        mixpanel.identify(String(user.admin_id));

        /**
         * set user properties
         */
        mixpanel.people.set({
            $email: user.email,
            $user_id: user.id,
            $first_name: user?.first_name ?? '',
            $last_name: user?.last_name ?? ''
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(user)]);

    useEffect(() => {
        function handleRouteChange(page: string, params?: { shallow: boolean }) {
            if (params?.shallow) {
                return;
            }
            mixpanel.track_pageview({ page });
        }

        handleRouteChange(router.pathname);

        router.events.on('routeChangeComplete', handleRouteChange);

        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
}
