import type {
    SocialProfileNetworkType,
    SocialProfileShortType,
    SocialProfileType
} from '@/types/socialProfileType';

export const INSTAGRAM = 'instagram';
export const FACEBOOK = 'facebook';
export const TWITTER = 'twitter';
export const LINKEDIN = 'linkedin';
export const TIKTOK_BUSINESS = 'tiktok_business';
export const YOUTUBE = 'youtube';
export const PINTEREST = 'pinterest';

export const formatSocialProfile = (profile: {
    type: SocialProfileNetworkType;
    username?: string;
    name?: string;
    slug?: string;
}) => {
    let main_name = '';
    let sub_name = '';
    if (typeof profile === 'object' && profile.type) {
        switch (profile.type) {
            case INSTAGRAM:
            case TWITTER:
                main_name = profile?.username ?? '';
                sub_name = profile?.name ?? '';
                break;
            case LINKEDIN:
            case TIKTOK_BUSINESS:
            case YOUTUBE:
            case PINTEREST:
                main_name = profile?.slug ?? '';
                break;
            case FACEBOOK:
                main_name = profile?.name ?? '';
                sub_name = profile?.username ?? '';
                break;
        }
    }

    return { main_name, sub_name };
};

export function getNetwork(profile: SocialProfileType) {
    if (profile.social_network_type === TIKTOK_BUSINESS) {
        return 'tiktok';
    }
    return profile.social_network_type;
}

export const formatAddedSocialProfile = (profile: {
    slug?: string;
    external_api_data?: { name: string };
}) => {
    return { main_name: profile?.slug ?? '', sub_name: profile?.external_api_data?.name ?? null };
};

export const formatSocialProfileType = (profile: {
    social_network_type: SocialProfileNetworkType;
}) => {
    let sub_name: SocialProfileShortType;
    if (typeof profile === 'object' && profile.social_network_type) {
        switch (profile.social_network_type) {
            case INSTAGRAM:
                sub_name = 'IG';
                break;
            case TWITTER:
                sub_name = 'X';
                break;
            case TIKTOK_BUSINESS:
                sub_name = 'TK';
                break;
            case LINKEDIN:
                sub_name = 'LI';
                break;
            case FACEBOOK:
                sub_name = 'FB';
                break;
            case YOUTUBE:
                sub_name = 'YT';
                break;
            case PINTEREST:
                sub_name = 'PI';
                break;
        }
    }

    return sub_name;
};

export const TIKTOK_ACCOUNT_NO_VIDEO = 'no_video_on_tiktok_profile';
export const PROFILE_TRIAL_LIMITATION = 'no_free_trial';
export const MANAGER_NOT_ALLOWED_BY_ADMIN_TO_ADD_SP = 'manager_not_allowed_by_admin_to_add_sp';
